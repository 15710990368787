<template>
  <div class="problemList">
    <!-- <com-carousel :banners="forumCarousel" :fullWidth="true" trigger="click" height="550px"></com-carousel> -->
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
                ref="searchFilter"
                :searchName="'搜索帖子'"
                :keywordOptions="{placeholder:'请输入帖子名称',prop:'keyword',autoName:'forum',autocomplete:true,width:'100%'}"
                :searchOptions="[
                  { name:'el-form-item' ,
                    options:{label:'分类' , prop:'type', type:'radio-group'  , data:{ name:'forum', keys:'type' } , defaultValue :'全部'}
                  },
              ]"
                v-model="where"
                :total="total"
                @search="currentChange(1)"
            ></search-filter>
          </el-card>
           <div class="operate">
            <div class="button-primary" :style="{ padding:'4px 20px'}" @click="setWherePrams({match_type:[1,2].indexOf(params.match_type) === -1 ? (userInfo.type === 2 ? 1 : 2) : null})" :class="{ myTeam: [1,2].indexOf(params.match_type) > -1 }" v-if="userInfo.type">
              我的帖子
            </div>
            <div class="button-primary" :style="{ padding:'4px 20px'}" @click="clickPost" v-if="userInfo.type">
              发布帖子
            </div>
          </div>
          <ForumList :forumList="forumList" v-loading="loading"></ForumList>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="currentChange"
              :page-size="pageSize"
              :current-page="page"
              hide-on-single-page
            >
            </el-pagination>
          </div>
        </div>
        <div class="content-right">
          <Recommend class="margin"></Recommend>
          <Notice></Notice>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { carousel } from "@/api/common.js";
import { forumList } from "@/api/forum.js";
import ForumList from "./components/ForumList.vue";
import Recommend from "./components/Recommend";
import Notice from "./components/Notice.vue";
import ComCarousel from "@/components/com/comCarousel";
import SearchFilter from "@/components/com/SearchFilter";
export default {
  components: {
    SearchFilter,
    ComCarousel,
    ForumList,
    Recommend,
    Notice,
  },
  data() {
    return {
      where:{},
      params:{},
      loading: false,
      paramsList: {},
      total: 0,
      page: 1,
      pageSize: 10,
      forumList: [],
      forumCarousel: [],
      showTeam:false
    };
  },
  methods: {
    routeUpdate(){ // 轮播
      // this.getIndexCarousel();
      this.where = {...this.$route.query,...this.$route.query.params};
    },
        //   点击创建
    clickPost () {
      this.$router.push("/forum/forumEditor")
    },
    setWherePrams  (params ,isOverride = false) {
      if (!this.userInfo.id) {
        this.$store.commit("SET" , {key:'lodinShow',value:true})
        return ;
      }
      params || {};
      this.page = 1
      if(isOverride){
        this.params = params
      }else{
        this.params = {...this.params,...params}
      }
      this.getForumList()
    },
    // getIndexCarousel() {
    //    let storageKey = "lt";
    //   let data = this.$storage.get(storageKey);
    //   if (data) {
    //     console.log(data, "缓存");

    //     this.forumCarousel = data.list;
    //   }
    //   carousel({ type: 8 })
    //     .then(({ data }) => {
    //       this.forumCarousel = data.list;
    //       this.$storage.set(storageKey, data);

    //       console.log(data, "论坛轮播");
    //     })
    //     .catch((err) => {
    //       console.log(err, "论坛轮播失败");
    //     });
    // },
    // 帖子列表
    getForumList(params) {
      this.loading = true;
      forumList({
        ...this.where,
        ...this.params,
        ...(params || {}),
        page: this.page,
        page_size: this.pageSize,
      })
        .then(({ data }) => {
          this.loading = false;
          this.forumList = data.data;
          this.total = data.total;
          console.log(data, "帖子成功");
        })
        .catch((err) => {
          this.loading = false;

          console.log(err, "帖子失败");
        });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getForumList();
    },
  },
};
</script>

<style lang="less" scoped>
.myTeam {
  background-color: #fff;
  border: 1px solid #00957e;
  color: #00957e;
  height: 24px;
  // box-sizing: border-box;
}
.operate {
  margin: 2.2% 0 1.8%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .new_problemList {
    margin-left: 1rem;
  }
}
.pagination {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 70%;
  }
  .content-right {
    width: 25%;
    .margin {
      // padding-bottom: 20%;
    }
  }
}
.banner {
  height: 100%;
  .el-image {
    display: inline-block;
    height: 100%;
  }
}
</style>