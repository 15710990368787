<!--  -->
<template>
  <div>
    <div class="HotRecommend">
      <div class="title">
        <!-- <el-image
                :src="require('@/assets/images/problemList/热门@2x.png')"
                style="width: 24px; height: 24px"
              ></el-image> -->
        <div>论坛公告</div>
      </div>
      <div
        class="HotRecommend-item"
        v-for="(v, i) in noticeList"
        :key="i"
        @click="forum(v)"
      >
        <el-image
          :src="require('@/assets/images/problemList/编组7@2x.png')"
          style="width: 24px; height: 24px"
        ></el-image>
        <span class="text">{{ v.title }}</span>
      </div>
    </div>
    <el-dialog
      title="论坛公告"
      :visible.sync="dialogVisible"
      width="30%"
      top="30vh"
    >
      <span>{{ title }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { notice } from "@/api/forum.js";

export default {
  //import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
    //这里存放数据
    return {
      noticeList: [],
      dialogVisible: false,
      title: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //获取推荐帖子阅读

    this.getNoticeList();
  },
  //方法集合
  methods: {
    // 推荐帖子阅读
    getNoticeList() {
      notice({page:1,page_size:5})
        .then(({ data }) => {
          this.noticeList = data.data;
        })
        .catch((err) => {
        });
    },
    forum(item) {
      if (localStorage.getItem("user_token")) {
        let obj={title:"公告消息",content:item.title}
        this.$router.push({ path: "/me/meFeedbackDetaild" ,query:{title:item.title,content:item.content}});
        return;
      }
      this.dialogVisible = true;
      this.title = item.title;
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
::v-deep {
  .el-dialog__header {
    text-align: center;
  }
}
.HotRecommend {
  margin-top: 40px;
  background-color: #fff;
  padding: 1rem;
  .title {
    display: flex;
    font-size: 18px;
    color: #00957e;
    line-height: 25px;
    font-weight: 600;
    > div {
      margin-right: 0.3rem;
    }
  }
  .HotRecommend-item {
    padding-left: 2rem;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #363636;
    margin-top: 0.8rem;
    cursor: pointer;
    .text {
      margin-left: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>